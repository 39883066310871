import { lazy, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LoaderProvider } from '@bluecodecom/loader';

import '@bluecodecom/fonts';

import { initI18n } from '@bluecodecom/common/i18n';
import { nativeService } from '@bluecodecom/common/services/native';
import { ThemeProvider } from '@bluecodecom/common/theme';

const App = lazy(() => import('./app'));

initI18n(
  { en: {}, de: {} },
  {
    interpolation: {
      escapeValue: false,
    },
  },
);

const Main = () => {
  useEffect(() => {
    nativeService.hideNavigation();
  }, []);

  return (
    <ThemeProvider observe>
      <LoaderProvider>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
